@import 'styles/colors.module.scss';
@import 'styles/sizes.module.scss';

.view {
    .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
