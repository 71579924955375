@import "styles/colors.module.scss";
@import "styles/sizes.module.scss";

.view {
  .fade {
    position: relative;
  }
  .fade::after {
    @media (min-width: 600px) {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      background-image: linear-gradient(
        to right,
        rgba(29, 29, 29, 1) 0%,
        transparent 100%
      );
      pointer-events: none;
    }
  }
  .scrollDown {
    position: absolute;
    bottom: 80px;
    width: 100%;
  }

  [data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
  }
  [data-title]:after {
    content: attr(data-title);
    background-color: #00ff00;
    color: #111;
    font-size: 150%;
    position: absolute;
    padding: 1px 5px 2px 5px;
    bottom: -1.6em;
    left: 0;
    white-space: nowrap;
    box-shadow: 1px 1px 3px #222222;
    opacity: 0;
    border: 1px solid #111111;
    z-index: 99999;
    visibility: hidden;
  }
  [data-title] {
    position: relative;
  }
}
