@import "styles/colors.module.scss";
@import "styles/sizes.module.scss";

.view {
  z-index: 2;

  .button {
    position: fixed;
    top: Grid(1);
    right: Grid(1);
  }
}
